.green-row {
  background-color: #ddffdd !important;
}

.red-row {
  background-color: #ffdddd !important;
}

.blue-row {
  background-color: #ddddff !important;
}

.grey-row {
  background-color: #e1e1e6 !important;
}

.red-text {
  color: #d93737 !important;
}
.green-text {
  color: #2eb85c !important;
}
.blue-text {
  color: #1f1498 !important;
}

.ag-font-style {
  user-select: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
}
